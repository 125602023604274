// Reset.less
// Adapted from Normalize.css http://github.com/necolas/normalize.css
// ------------------------------------------------------------------------

// Display in IE6-9 and FF3
// -------------------------

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

// Display block in IE6-9 and FF3
// -------------------------

audio,
canvas,
video {
  display: inline-block;
}

// Prevents modern browsers from displaying 'audio' without controls
// -------------------------

audio:not([controls]) {
    display: none;
}

// Base settings
// -------------------------

html, body { margin: 0; padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td { margin: 0; padding: 0; border: 0; font-size: 100%; line-height: 1; font-family: inherit;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

// Focus states
a:focus {
  outline: 0;
}
// Hover & Active
a:hover,
a:active {
  outline: 0;
}

// Prevents sub and sup affecting line-height in all browsers
// -------------------------

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

// Img border in a's and image quality
// -------------------------

img {
  //max-width: 100%;
  //height: auto;
  //border: 0;
  -ms-interpolation-mode: bicubic;
}

// Forms
// -------------------------

// Font size in all browsers, margin changes, misc consistency
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
}
button::-moz-focus-inner,
input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
  padding: 0;
  border: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; // Cursors on all buttons applied consistently
  -webkit-appearance: button; // Style clicable inputs in iOS
}
input[type="search"] { // Appearance in Safari/Chrome
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
}
textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}



// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
//
// In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
// DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
// However, they DO support removing the click delay via `touch-action: manipulation`.
// See:
// * http://v4-alpha.getbootstrap.com/content/reboot/#click-delay-optimization-for-touch
// * http://caniuse.com/#feat=css-touch-action
// * http://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}






@font-face {
  font-family: 'Graphik Web';
  src:  url('../fonts/Graphik-Regular-Web.woff2') format('woff2'),
        url('../fonts/Graphik-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src:  url('../fonts/Graphik-Semibold-Web.woff2') format('woff2'),
        url('../fonts/Graphik-Semibold-Web.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
}






// ---------
// HTML
// ---------
html{
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after{
  box-sizing: inherit;
}


// ---------
// BODY
// ---------
body{
  margin: 0;
  line-height: 1;
  font-size:12px;
  font-family: 'Graphik Web', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color:black;
  background:white;
  //-webkit-font-smoothing: antialiased;
}

.disable-scroll {
	margin: 0;
	height: 100%;
	overflow: hidden;
}



// ---------
// LINKS
// ---------
a{
	color:black;
	text-decoration:none;
	text-decoration-skip:ink;

	-moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
a:hover{
	color:#00eda4 !important;
	text-decoration:none;
}





// ---------
// HEADER
// ---------
header{
	position:fixed;
	left:0;
	top:0;
	padding:6px 14px 0 14px;
	width:100%;
	background:white;
	z-index:10;
	overflow:hidden;

	h1{
		float:left;
		margin:0 20px 0 0;
		text-align:left;
	}
	a{
		color:inherit;
		font-size:64px;
		line-height:1em;
		font-weight:600;
		letter-spacing:-2.4px;
	}
	ul{
		float:right;
		list-style: none;
		overflow:hidden;
	}
	li{
		float:left;
	}
	a{
		&:hover{
			svg{
				fill:#00eda4 !important;
			}
		}
	}
	.social-item{
		margin:0 0 0 6px;
		//padding:17px 0 0 0;
	}
	svg{
		width:36px;
		height:36px;
		-moz-transition: all .5s ease;
	    -o-transition: all .5s ease;
	    -webkit-transition: all .5s ease;
	    transition: all .5s ease;
	}
}
@media screen and (max-width: 1280px){

	header{
		h1{
			margin:0 14px 0 0;
		}
		a{
			font-size:48px;
		}
		svg{
			width:26px;
			height:26px;
		}
	}

}
@media screen and (max-width: 640px){

	header{
		h1{
			margin:0 10px 0 0;
		}
		a{
			font-size:24px;
			letter-spacing:-1px;
		}
		svg{
			position:relative;
			top:1px;
			width:24px;
			height:24px;
		}
	}

}

.headerAuth{
	//float:right;
	display:inline-block;
	padding:20px 0 0 20px;
	vertical-align:top;

	span{
		display:inline-block;
		font-size:16px;
		line-height:28px;
		vertical-align:top;
	}
	button{
		display:inline-block;
		margin:0 0 0 20px;
		padding:4px 10px;
		border:0;
		border-radius:4px;
		font-size:12px;
		line-height:20px;
		vertical-align:top;

	}
}
.headerProjectEdit{
	color:#000;
	background:#00eda4;

	&:hover{
		color:#00eda4;
		background:#000;
	}
}
.headerAuthLogout{
	color:#fff;
	background:#000;

	&:hover{
		color:#fff;
		background:#ff0000;
	}
}
@media screen and (max-width: 1280px){

	.headerAuth{
		padding-top:8px;

		span{
			font-size:14px;
		}
		button{
			margin:0 0 0 12px;
		}
	}

}
@media screen and (max-width: 900px){

	.headerAuth{
		span{
			font-size:14px;
			line-height:22px;
		}
		button{
			margin:0 0 0 12px;
			padding:4px 6px;
			font-size:11px;
			line-height:14px;
		}
	}

}
@media screen and (max-width: 640px){

	.headerAuth{
		padding-top:2px;

		span{
			font-size:12px;
			line-height:20px;
		}
		button{
			margin:0 0 0 8px;
			padding:2px 6px;
		}
	}

}
@media screen and (max-width: 500px){

	.headerAuth{
		padding-left:10px;

		span{
			display:none;
		}
	}

}
@media screen and (max-width: 400px){

	.headerAuth{
		padding-left:6px;

		button{
			margin:0 0 0 6px;
			padding:4px 4px;
		}
	}

}

.is-dragging header {
	pointer-events: none;
}

nav{
	width:100%;
	padding:0 0 0 2px;
	/*position:fixed;
	left:0;
	top:60px;
	padding:0 14px 0 18px;

	background:white;
	z-index:10;*/
	overflow:hidden;

	ul{
		width:100%;
		list-style: none;
	}
	li{
		float:left;
		margin:0 20px 0 0;
	}
	a{
		font-size:20px;
		line-height:1.24em;
		font-weight:600;
		letter-spacing:-1px;
	}
}
@media screen and (max-width: 640px){

	nav{
		display:none;
	}

}

.current-category-link {
	color: #00eda4
}





// ---------
// SECTION
// ---------
section{
	padding:176px 10px 0 10px;
}

@media screen and (max-width: 640px){

	section{
		padding-top:50px;
	}

}


// ---------
// POST - DETAIL
// ---------
.post-detail{
	header{
		background:none;
	}
	nav{
		display:none;
	}
	.row{
		cursor:url(../img/close-cursor.png) 12 12, pointer;
	}
}
.detail-footer{
	position:relative;
	z-index:10;
}




.btn-edit,
.btn-del{
	display:inline-block;
	margin:4px 2px 4px 0;
	padding:0 8px;
	height:24px;
	border-radius:2px;
	font-size:11px;
	font-weight:400;
	line-height:20px;
	vertical-align:top;
}
.btn-edit{
	color:white;
	background:black;

	&:hover{
		color:black;
		background:#00eda4;
	}
}
.btn-del{
	color:#ff0000;
	background:none;

	&:hover{
		color:white;
		background:#ff0000;
	}
}


// ---------
// POST EDIT
// ---------
.post-edit{
	position:fixed;
	left:0;
	top:0;
	padding:40px 0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,.8);
	z-index:1000;

	overflow:hidden;
	overflow-x:hidden;
	overflow-y:auto;
	-webkit-overflow-scrolling: touch;
}
.post-edit-content{
	margin:0 auto 0;
	padding:0 10px;
	width:100%;
	max-width:800px;
	background:white;

	h1,h2{
		margin:0 0 8px 0;
		font-size:16px;
		line-height:1.6em;
		font-weight:600;
		letter-spacing:-0.4px;
	}
	h2{
		margin:10px 0 6px 0;
	}
	.article-tab-content{
		padding-left:0;
	}
}

// POST EDIT - HEADER
// ---------
.post-edit-header{
	position:relative;
	//margin:0 0 6px 0;
	//border-bottom:1px solid #000;

	button{
		height:35px;
		min-width:35px;
		font-size:12px;
		line-height:35px;
		font-weight:600;
		letter-spacing:-0.4px;
		text-align:center;
		outline:0;

		&:focus{
			outline:0;
		}
	}
	.post-edit-language-current{
		color:#00eda4;
		background:black;
	}
	.btn-save{
		position:absolute;
		right:45px;
		top:2px;
		padding:0 12px;
		width:auto;
		height:30px;
		border-radius:4px;
		font-size:12px;
		font-weight:400;
		line-height:30px;
		color:#fff;
		background:#000;

		&:hover{
			color:#000;
			background:#00eda4;
		}
	}
}
.post-btn-cancel{
	position:absolute;
	right:0;
	top:0;
	width:35px;
	height:35px;
	font-size:14px;
	line-height:35px;
	background:#f5f5f5;
	text-align:center;
	cursor:pointer;

	&:hover{
		background:black;
	}
}


// POST EDIT - IMAGE
// ---------
.item-post-image{
	margin:0 0 8px 0;
}
.item-post-image-label{
	display:inline-block;
	min-width:90px;
	height:28px;
	font-size:12px;
	line-height:1.3em;
	font-weight:600;
	letter-spacing:-0.4px;
	vertical-align:top;

	span{
		display:block;
		font-size:9px;
		font-weight:400;
		line-height:1.3em;
		letter-spacing:0px;
	}
}
.post-edit-image {
	position:relative;
	display:inline-block;
	width: 100px;
	min-height: 50px;
	background: silver;
	vertical-align:top;
	cursor:pointer;
	overflow:hidden;

	> img {
		float:left;
		width: 100%;
		height: auto;
	}
	.btn-imageedit{
		position:absolute;
		left:0;
		bottom:0;
		width:20px;
		height:20px;
		font-size:9px;
		line-height:22px;
		color:#FFF;
		background:url(../img/iconfinder_ic_crop_48px_352293.svg) center center no-repeat #fff;
		background-size:16px auto;
		text-indent:-9999px;
		z-index:99;

		&:hover{
			background-color:#00eda4;
		}
	}
}

// POST EDIT - PARAMS
// ---------
.post-edit-params{
	margin:0 0 10px 0;
	padding:0 0 15px 0;
	border-bottom:1px solid #000;

	.p-slug{
		margin:-4px 0 6px 0;
		padding:0 0 0 93px;
		font-size:10px;
	}
	.p-markdowninfo{
		padding:0 0 4px 0;
		text-align:right;
		font-size:11px;
	}
}
.item-input,
.item-input-color,
.item-textarea{
	display:block;
	margin:0 0 8px 0;

	label{
		display:inline-block;
		min-width:90px;
		height:28px;
		font-size:12px;
		line-height:28px;
		font-weight:600;
		letter-spacing:-0.4px;
		vertical-align:top;
	}
	input,
	textarea{
		padding:0 6px;
		width:300px;
		height:28px;
		font-size:12px;
		line-height:26px;
		border:1px solid #ccc;
	}
	textarea{
		padding:6px 8px;
		width:100%;
		max-width:100%;
		height:110px;
		min-height:110px;
		line-height:1.5em;
	}
}
.item-input-color{
	label{
		min-width:120px;
	}
	input{
		padding:0;
		width:28px;
		cursor:pointer;
	}
}
.item-textarea{
	label{
		display:block;
		margin:0;
	}
	textarea{
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	}
}
.item-radio{
	display: block;
	width:100%;
	margin:0 0 6px 0;
	font-size:12px;
	line-height:12px;

	input{
		vertical-align:top;
	}
}

// POST EDIT - SELECTS (radio)
// ---------
.post-edit-selects{
	margin:0 0 20px 0;
	overflow:hidden;
}
.item-third{
	float:left;
	width:33%;
}

// POST EDIT - BTNs
// ---------
.post-edit-save{
	margin:0 -10px;
	padding:10px 10px;
	width:auto;
	border-top:1px solid #000;
	text-align:right;

	button{
		display:inline-block;
		padding:0 12px;
		height:30px;
		border-radius:4px;
		font-size:14px;
		font-weight:400;
		line-height:30px;
		color:white;
		background:black;
		vertical-align:top;

		&:hover{
			color:black;
			background:#00eda4;
		}
		&:disabled,
		&:disabled:hover{
			color:#fff;
			background:#bbb;
			cursor:default;
		}
	}
	.btn-cancel{
		display:inline-block;
		margin:0 10px 0 0;
		height:30px;
		font-size:10px;
		line-height:30px;
		vertical-align:top;
		cursor:pointer;
	}
}





// ---------
// ADMIN - ROW + IMAGE
// ---------
.post-image-row{
	overflow:hidden;
}

// ADMIN - ROW
// ---------
.admin-row{
	float:left;
	//padding:0 0 30px 0;
	width:300px;
	background:#f3f3f3;
	text-align:center;
	//overflow:hidden;
}
.admin-row-item{
	margin:0 0 4px 0;

	&> div{
		//height:100%;
	}
	.row{
		overflow:visible;

		&:after {
			content:"";
			display:table;
			clear:both;
		}
	}
	.row-fullscreen,
	.row-wide,
	.row-wide10,
	.row-third-content,
	.row-quarter-content,
	.row-half-content,
	.row-half-content-smaller,
	.row-half-content-big,
	.row-half-full,
	.row-half-full-big,
	.row-half-half-content,
	.row-video-content,
	.row-quarter-content-half{
		background:#c5c5c5;
		cursor:pointer;
		overflow:hidden;

		&:hover{
			background:black;

			.admin-row-item-span{
				color:#00eda4;
			}
		}
	}
	.btn-remove,
	.btn-imageedit{
		position:absolute;
		left:0;
		top:0;
		width:20px;
		height:20px;
		font-size:12px;
		line-height:20px;
		color:#FFF;
		background:#000;
		text-align:center;
		z-index:99;
	}
	.btn-remove{
		&:hover{
			background:#ff0000;
		}
	}
	.btn-imageedit{
		top:auto;
		bottom:0;
		font-size:9px;
		line-height:22px;
		background:url(../img/iconfinder_ic_crop_48px_352293.svg) center center no-repeat #fff;
		background-size:16px auto;
		text-indent:-9999px;

		&:hover{
			background-color:#00eda4;
		}
	}
}
.admin-row-item-span{
	position:absolute;
	left:0;
	top:50%;
	margin-top:-10px;
	width:100%;
	height:20px;
	font-size:11px;
	font-weight:600;
	line-height:20px;
	color:black;
	text-align:center;
}
.border-label{
	display:block;
	padding:2px 6px;
	line-height:20px;
	text-align:left;

	input{
		position:relative;
		top:-1px;
	}
}

.admin-row-action{
	position:absolute;
	left:100%;
	//right:0;
	top:0;
	width:18px;
	height:100%;
	text-align:center;
	background:black;

	button{
		display:block;
		position:absolute;
		left:0;
		bottom:0;
		width:100%;
		height:18px;
		font-size:14px;
		font-weight:600;
		color:black;
		background:#f5f5f5;

		&:hover{
			color:white;
			background:#ff0000;
		}
	}
	.btn-switch{
		top:24px;
		height:24px;
		font-size:12px;

		&:hover{
			color:white;
			background:black;
		}
	}
	.btn-draggable{
		position:absolute;
		left:0;
		top:0;
		display:block;
		width:100%;
		height:24px;
		line-height:24px;
		font-size:11px;
		color:black;
		background:#00eda4;
		text-align:center;
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;

		&:hover{
			color:white;
			background:black;
		}
	}
}


// ADMIN - ROW - ADD
// ---------
.btn-add-row{
	display:inline-block;
	margin:20px 0;
	width:40px;
	height:40px;
	border-radius:20px;
	font-size:18px;
	line-height:40px;
	color:black;
	background:#00eda4;
	vertical-align:top;
	text-align:center;

	&:hover{
		color:white;
		background:black;
	}
}
.admin-add-row-list{
	width:100%;
	text-align:center;
	list-style:none;

	li{
		margin:0 auto 4px;
		width:160px;
	}
	button{
		display:block;
		width:100%;

		&:hover{
			background:#00eda4;
		}
	}
}


// ADMIN - IMAGE
// ---------
.files-editor {
	float:left;
	margin:0 0 0 40px;
	padding:0 0 20px 0;
	width: 200px;

	.btn-upload{
		position:relative;
		display:block;
		margin:0 0 20px 0;
		padding:10px 10px 9px 36px;
		width:100%;
		font-size:12px;
		line-height:18px;
		font-weight:700;
		background:#00eda4;
		text-align:left;
		cursor:pointer;
		outline:0;

		&:before,
		&:after{
			content:'';
			position:absolute;
			left:0;
			top:0;
			width:36px;
			height:100%;
			background:url(../img/ico-picture-dark.svg) center 48% no-repeat;
			background-size:auto 18px;
		}
		&:after{
			background-image:url(../img/ico-picture-white.svg);
			visibility:hidden;
		}
		&:focus{
			outline:0;
		}
		&:hover{
			color:white;
			background:black;

			&:before{
				visibility:hidden;
			}
			&:after{
				visibility:visible;
			}
		}
	}
	.btn-remove{
		position:absolute;
		right:0;
		top:0;
		width:20px;
		height:20px;
		line-height:18px;
		font-weight:700;
		background:#fff;
		cursor:pointer;
		z-index: 1;

		&:hover{
			background:#00eda4;
		}
	}
	div{
		position:relative;
		margin:10px 0 0 0;
		width:100%;
		height:auto;
		//cursor:pointer;
		overflow:hidden;
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;

		/*&:hover{
			p{
				color:black;
				background:#fff;
				//background:#00eda4;
			}
		}*/
		p{
			position:absolute;
			left:0;
			top:0;
			padding:0 6px;
			font-size:10px;
			line-height:18px;
			color:white;
			background:black;
		}
	}
	img {
		float:left;
		width: 100%;
		height:auto;
	}
}
.files-editor-video-file{
	background:#000;

	iframe{
		float:left;
		width:100%;
		height:auto;
		border:0;
	}
}








// ---------
// ARTICLE - TAB
// ---------
.article{
	//padding:0 4px;

	.article-tab-content{
		button{
			display:inline-block;
			margin:4px 2px 4px 0;
			padding:0 8px;
			height:24px;
			border:0;
			border-radius:2px;
			font-size:11px;
			font-weight:400;
			line-height:20px;
			color:white;
			background:black;
			vertical-align:top;
			cursor:pointer;

			-moz-transition: all .5s ease;
		    -o-transition: all .5s ease;
		    -webkit-transition: all .5s ease;
		    transition: all .5s ease;

			&:hover{
				color:black;
				background:#00eda4;
			}
		}
	}
	.btn-add-new{
		display:inline-block;
		margin:0 0 20px 0;
		padding:0 8px;
		font-size:14px;
		font-weight:bold;
		line-height:30px;
		color:#333;
		background:#00eda4;
		vertical-align:top;

		-moz-transition: all .5s ease;
	    -o-transition: all .5s ease;
	    -webkit-transition: all .5s ease;
	    transition: all .5s ease;

		&:hover{
			color:#fff;
			background:#000;
		}
	}
}
.article-tab{
	padding-right:4px;
	margin:0 0 40px -10px;
	max-width:800px;
}
.article-tab-head{
	display:block;
	margin:0 0 10px 14px;
	padding:2px 0;
	//width:100%;
	border-top:1px solid #000;
	cursor:pointer;

	h2{
		position:relative;
		display:inline-block;
		padding:0 28px 0 0;
		font-size:22px;
		line-height:1.24em;
		font-weight:600;
		//letter-spacing:-1px;
		vertical-align:top;

		&:after{
			content:'';
			position:absolute;
			right:0;
			top:50%;
			margin-top:-8px;
			width:20px;
			height:14px;
			background:url(../img/ico-arrow-top.svg) right center no-repeat;
			background-size:contain;
			-moz-transition: all .4s ease;
		    -o-transition: all .4s ease;
		    -webkit-transition: all .4s ease;
		    transition: all .4s ease;
		}
	}
	button{
		position:relative;
		float:right;
		margin:0 0 0 4px;
		width:30px;
		height:26px;
		border:0;
		background:#ccc;
		text-indent:-9999px;
		cursor:pointer;
		outline:0;

		-moz-transition: all .5s ease;
	    -o-transition: all .5s ease;
	    -webkit-transition: all .5s ease;
	    transition: all .5s ease;

	    &:focus{
	    	outline:0;
	    }
	    &:before{
	    	content:'';
	    	position:absolute;
	    	left:0;
	    	top:0;
	    	width:100%;
	    	height:100%;
	    	background:url(../img/ico-arrow-prev-white.svg) center center no-repeat;
	    	background-size:10px auto;
	    	transform: rotate(90deg);
	    }
		&:hover{
			background:#00eda4;
		}
	}
	.btn-up{
		&:before{
	    	transform: rotate(90deg);
	    }
	}
	.btn-down{
		&:before{
	    	transform: rotate(-90deg);
	    }
	}
}
.article-tab-head-act{
	.article-tab-head();

	h2{
		&:after{
			margin-top:-7px;
			-webkit-transform: rotate(-180deg);
			-moz-transform: rotate(-180deg);
			-ms-transform: rotate(-180deg);
			-o-transform: rotate(-180deg);
			transform: rotate(-180deg);
		}
	}
}
.article-tab-content{
	padding:0 0 0 14px;
	transition: height 0.3s ease;
	overflow: hidden;
	//display:none;

	h3 {
		margin:0 0 1px 0;
		font-size: 12px;
		font-weight:600;
		line-height: 1.4em;
	}
	p{
		margin:0 0 20px 0;
		font-size:12px;
		line-height:1.4em;
	}
	ul,ol{
		margin:0 0 20px 0;
	}
	ul{
		li{
			&:before{
				content:'—';
			}
		}
	}
	ol{
		counter-reset:li;

		li{
			counter-increment:li;

			&:before{
				content:counter(li)".";
			}
		}
	}
	li{
		position:relative;
		margin:0 0 8px 0;
		padding-left:20px;
		font-size:12px;
		line-height:1.4em;
		list-style:none;

		&:before{
			position:absolute;
			left:4px;
			top:0;
			font-size:12px;
			line-height:1.4em;
		}
	}
	.zapis-v-rejstriku{
		margin-bottom:14px;
		max-width:50%;
	}
	a{
		color:#7d7d7d;
	}
	.person-name{
		a{
			color:#000;
		}
	}
}
.article-tab-content-collapsed {
	transform: scaleY(0);
}
.column-list{
	font-size:0;
	line-height:0;

	.column{
		display:inline-block;
		margin:0 0 14px 0;
		width:33%; /* backup - aby nemusel menit HTML a porad to fungovalo */
		vertical-align:top;

		p{
			margin:0;
		}
	}
	.column-third{
		width:33%;
	}
	.column-half{
		width:50%;
	}
	.column-full{
		display:block;
		width:100%;
	}
}
@media screen and (max-width: 650px){

	.column-list{
		.column{
			width:50%; /* backup - aby nemusel menit HTML a porad to fungovalo */
		}
		.column-third{
			width:50%;
		}
		.column-half{
			width:50%;
		}
		.column-full{
			width:100%;
		}
	}

}
@media screen and (max-width: 500px){

	.column-list{
		.column{
			display:block;
			width:100%; /* backup - aby nemusel menit HTML a porad to fungovalo */
		}
		.column-third{
			width:100%;
		}
		.column-half{
			width:100%;
		}
		.column-full{
			width:100%;
		}
	}

}


// ARTICLE - PEOPLE
// ---------
.item-third .people-list{
	position:relative;
	display:block;
	margin:5px 0 0 0;
	padding:0 0 0 23px;
	line-height:18px;

	span{
		position:absolute;
		left:0;
		top:0;
		width:18px;
		height:18px;
		font-size:10px;
		line-height:18px;
		background:#e6e6e6;
		text-align:center;
		cursor:pointer;

		&:hover{
			color:#fff;
			background:#000;
		}
	}
}



// POST EDIT - ARTICLE - TAB
// ---------
.post-edit-content{
	.article-tab{
		margin:0 -10px;
		padding:0 10px;
		width:auto;
	}
	.article-tab-head,
	.article-tab-head-act{
		padding:0;
		margin:0;

		h2{
			margin:0;
			padding:0 22px 0 0;
			font-size:16px;
			line-height:40px;

			&:after{
				width:12px;
				background-size:12px auto;
			}
		}
	}
}


// ARTICLE - TAB - COLUMN
// ---------
.article-tab-content .article-column-2{
	-moz-columns:0 2;
	-webkit-columns:0 2;
	columns:0 2;

	p{
		padding-right:10px;
		font-size:16px;
		line-height:1.3em;
	}
	li{
		padding-left:28px;
		font-size:16px;
		line-height:1.3em;

		&:before{
			font-size:16px;
			line-height:1.3em;
		}
	}
}

@media screen and (max-width: 640px){

	.article-tab-content .article-column-2{
		-moz-columns:0 1;
		-webkit-columns:0 1;
		columns:0 1;
	}

}


// ARTICLE - TAB - COLUMN LIST
// ---------
.article-tab-content .article-address{


}


// ARTICLE - TAB - CONTACT
// ---------
.article-tab-content .article-contact-list{
	ul{
		margin:0;
		font-size:0;
		line-height:0;
	}
	li{
		position:relative;
		display:inline-block;
		margin:0 0 10px 0;
		padding-left:0;
		padding-right:20px;
		width:33%;
		font-size:12px;
		line-height:1.4em;
		vertical-align:top;

		&:before{
			display:none;
		}
		&.drag-target{
			//margin-left:120px;
			//padding-left:100px;

			&:after{
				content:'';
				position:absolute;
				//right:100%;
				left:0;
				top:0;
				width:20%;
				height:100%;
				border:2px dashed #00eda4;
				background:#fff;
				//opacity:0.3;
				z-index:5;
			}
		}
		button{
			display:block;
		}
	}
	h3{
		display:block;
		margin:0 0 1px 0;
		font-size:12px;
		line-height:1.4em;
		font-weight:600;
	}
	.person-name{
		cursor:pointer;
		transition: all .2s ease-in-out;

		&:hover{
			color:#00eda4;
		}
	}
	.position{
		display:block;
		font-size:12px;
		line-height:1.4em;
	}

	.person-detail{
		margin:0 0 0 -14px;
		padding:16px 14px;
		background:#000;

		h3,p,li{
			color:#fff;
		}
		h3{
			margin:0 0 10px 0;
			font-size:18px;
			font-weight:normal;
		}
		p{
			max-width:65%;
		}
		ul{
			margin-top:20px;
		}
		li{
			margin-bottom:8px;

			a{
				display:block;
				color:#fff;
				font-size:12px;
				line-height:1.4em;
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;

				&:hover{
					color:#00eda4;
				}
			}
		}
		button{
			margin:20px 0 0 0;
			font-size:40px;
			font-weight:normal;
			color:#fff;
			background:#000;
			text-align:left;

			&:focus{
				outline:0;
				border:0;
			}
			svg{
				width:24px;
				height:24px;
			}
		}
	}
}
@media screen and (max-width: 650px){

	.article-tab-content .article-contact-list{
		.person-detail{
			scroll-margin-top: 50px;
			p{
				max-width:100%;
			}
		}
	}

}
@media screen and (max-width: 500px){

	.article-tab-content .article-contact-list{
		li{
			width:50%;
		}
	}

}
@media screen and (max-width: 350px){

	.article-tab-content .article-contact-list{
		li{
			width:100%;
		}
	}

}

// ARTICLE - TAB - EDITs
// ---------
.article-edit{
	width:100%;

	.article-tab-head-act{
		h2{
			min-width:200px;
			padding:10px 0 0 0;

			input{
				padding:4px 8px;
				min-width:200px;
				font-size:14px;
			}
			&:after{
				display:none;
			}
		}
	}
	textarea{
		width:100%;
		max-width:100%;
		min-width:100%;
		height:300px;
		min-height:300px;
	}
	.p-markdowninfo{
		padding:4px 0 0 0;
		font-size:11px;
		text-align:right;
	}
}
.article-edit-btns{
	padding:0;
	width:100%;

	a{
		display:inline-block;
		margin:4px 2px 4px 0;
		padding:0 8px;
		height:24px;
		border:0;
		border-radius:2px;
		font-size:11px;
		font-weight:400;
		line-height:20px;
		vertical-align:top;
		cursor:pointer;
	}
	a{
		color:#000;
		line-height:24px;
		background:none;

		&:hover{
			color:white;
			background:#000;
		}
	}
}






.fadeout {
	opacity: 0;
	transition: opacity 300ms ease-out;
}

.fadeout-visible {
	opacity: 1;
}

.crop-image-container {
	background: #fff;
	position: absolute !important;
	z-index: 1100;

	&:before{
		content:'';
		position:fixed;
		left:0;
		top:0;
		width:100%;
		height:100%;
		background:black;
	}
}
.crop-image-container-action{
	position:relative;
	padding:10px 10px;
	background:#fff;
	text-align:right;
	z-index:2;

	button{
		margin:0 0 0 10px;
		padding:8px 12px;
		border-radius:4px;
		font-size:12px;
		font-weight:bold;
		color:#000;
		background:#00eda4;

		&:hover{
			color:#fff;
			background:#000;
		}
		&:disabled,
		&:disabled:hover{
			color:#fff;
			background:#bbb;
			cursor:default;
		}
		&.btn-cancel{
			background:none;

			&:hover{
				color:#00eda4;
				background:none;
			}
		}
	}
}

.ReactCrop {
	width:100%;
	height:100%;
	cursor: default !important;
	vertical-align:top;
}

.files-editor-video-file:after {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}



.activity-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.8;
	z-index: 1200;

	p{
		position:fixed;
		left:0;
		top:50%;
		margin-top:-15px;
		width:100%;
		height:30px;
		font-size:20px;
		line-height:30px;
		font-weight:bold;
		color:#fff;
		text-align:center;
	}
}

.internal-person h3, .internal-person span {
	opacity: 0.4;
}

.sketch-picker label {
	min-width: auto;
}
.item-related-cols {
	columns: 2;
}