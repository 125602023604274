.section-detail{
	padding:0;
}
.detail-content,
.detail-content-offsetted{
	position:relative;
	width:100%; /* iPad fix - hide "info" text */
	overflow:hidden; /* iPad fix - hide "info" text */

	h1{
		position:fixed;
		left:0;
		bottom:0;
		padding:0 14px 24px 14px;
		font-size:32px;
		line-height:1.2em;
		color:#fff;
		z-index:10;

		button{
			display:block;
			font-size:inherit;
			font-weight:600;
			line-height:inherit;
			color:inherit;
			background:no-repeat;
			cursor:pointer;
			letter-spacing:-1.8px;
			text-align:left;
			outline:0;
			-moz-transition: all .5s ease;
		    -o-transition: all .5s ease;
		    -webkit-transition: all .5s ease;
		    transition: all .5s ease;

			&:hover{
				color:#00eda4 !important;
			}
			&:focus{
				outline:0;
			}
		}
	}
	img{
		max-width:100%;
	}
}
.detail-content-offsetted {
	margin-bottom: -20px;
}
.detail-footer{
	padding:20px 16px;
	width:100%;
	color:white;
	background:black;
	
	h1{
		margin:0 0 8px 0;
		font-size:30px;
		line-height:1.2em;
	}
	.p-tagslist{
		span{
			position:relative;
			display:inline-block;
			padding:0 0 0 12px;
			vertical-align:top;
			
			&:first-child{
				padding-left:0;

				&:before{
					display:none;
				}
			}
			&:before{
				content:'/';
				position:absolute;
				left:0;
				top:0;
				width:12px;
				text-align:center;
			}
		}
	}
}

.detail-footer-top{
	p{
		margin:0 0 3px 0;
		color:#a0a0a0;
	}
}
.detail-footer-content{
	padding:10px 0 0 0;
	font-size:12px;
	line-height:1.4em;
	overflow:hidden;
	
	h3{
		font-size:12px;
		line-height:1.4em;
	}
	p{
		margin:0 0 18px 0;
		font-size:12px;
		line-height:1.4em;

		strong{
			//display:block;
			font-size:inherit;
			line-height:inherit;
		}
		a{
			color:white;
		}
	}

	ul,ol{
		margin:0 0 18px 0;
	}
	ul{
		li{
			&:before{
				content:'—';
			}
		}
	}
	ol{
		counter-reset:li;

		li{
			counter-increment:li;

			&:before{
				content:counter(li)".";
			}
		}
	}
	li{
		position:relative;
		margin:0 0 8px 0;
		padding-left:20px;
		font-size:12px;
		line-height:1.4em;
		list-style:none;

		&:before{
			position:absolute;
			left:4px;
			top:0;
			font-size:12px;
			line-height:1.4em;
		}
	}
}
.detail-footer-content-left{
	float:left;
	padding-right:60px;
	width:360px;
}
.detail-footer-content-right{
	float:left;
	width:300px;
}
.detail-footer-btns{
	padding:40px 0;

	button{
		display:inline-block;
		width:46px;
		height:40px;
		font-size:34px;
		font-weight:600;
		line-height:40px;
		color:#fff;
		background:none;
		text-align:left;
		outline:0;
		vertical-align:top;
		-moz-transition: all .5s ease;
	    -o-transition: all .5s ease;
	    -webkit-transition: all .5s ease;
	    transition: all .5s ease;

		&:hover{
			color:#00eda4;
		}
		&:focus{
			outline:0;
		}
	}
	.btn-mobile-prev,
	.btn-mobile-next{
		display:none;
		position:relative;
		//display:inline-block;
		width:40px;
		height:40px;
		background-position:0 center;
		background-size:12px auto;
		background-repeat:no-repeat;
		outline:0;
		vertical-align:top;
		text-indent:-9999px;

	    &:after{
	    	content:'';
	    	position:absolute;
	    	left:0;
	    	top:0;
	    	width:100%;
	    	height:100%;
			background-position:0 center;
			background-size:12px auto;
			background-repeat:no-repeat;
	    	opacity:0;
			-moz-transition: all .5s ease;
		    -o-transition: all .5s ease;
		    -webkit-transition: all .5s ease;
		    transition: all .5s ease;
	    }
		&:hover{
			&:after{
				opacity:1;
			}
		}
	}
	.btn-mobile-prev{
		background-image:url(../img/ico-arrow-prev-white.svg);

		&:after{
			background-image:url(../img/ico-arrow-prev.svg);
		}
	}
	.btn-mobile-next{
		background-image:url(../img/ico-arrow-next-white.svg);

		&:after{
			background-image:url(../img/ico-arrow-next.svg);
		}
	}
}

.detail-btn-prev,
.detail-btn-next{
	position:fixed;
	top:50%;
	margin-top:-140px;
	width:230px;
	height:280px;
	line-height:280px;
	opacity:0;
	//visibility:hidden;
	-moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
	
	&:hover{
		opacity:1;
		visibility:visible;
	}
	&:before{
		content:'';
		position:absolute;
		top:0;
		width:50px;
		height:100%;
		background-position:center;
		background-repeat:no-repeat;
	}
	p{
		display:inline-block;
		font-size:16px;
		line-height:1.4em;
		color:#00eda4;
		vertical-align:middle;
		text-transform:uppercase;
	}
}
.detail-btn-prev{
	left:0;
	padding-left:50px;
	cursor:url(../img/ico-arrow-prev.svg) 12 12, pointer;

	&:before{
		left:0;
		background-image:url(../img/ico-arrow-prev.svg);
	}
}
.detail-btn-next{
	right:0;
	padding-right:50px;
	text-align:right;
	cursor:url(../img/ico-arrow-next.svg) 12 12, pointer;

	&:before{
		right:0;
		background-image:url(../img/ico-arrow-next.svg);
	}
}

@media screen and (max-width: 1024px){
	
	.detail-footer-btns{
		.btn-mobile-prev,
		.btn-mobile-next{
			display:inline-block;
		}
	}
	.detail-btn-prev,
	.detail-btn-next{
		display:none;
	}

}

@media screen and (max-width: 760px){

	.detail-content{
		h1{
			font-size:26px;

			button{
				letter-spacing:-1px;
			}
		}
	}
	
	.detail-footer-content-left{
		width:65%;
	}
	.detail-footer-content-right{
		width:35%;
	}

}

@media screen and (max-width: 640px){

	.detail-content{
		h1{
			font-size:22px;

			button{
				letter-spacing:-0.4px;
			}
		}
	}

	.detail-footer{
		h1{
			font-size:20px;
		}
	}

	.detail-footer-content-left{
		padding-right:0;
		float:none;
		width:100%;
	}
	.detail-footer-content-right{
		float:none;
		width:100%;
	}

}