.article-item,
.btn-add-post{
	position:relative;
	display:inline-block;
	margin:0 0 18px 0;
	padding:0 4px;
	// width:20%;
	width: calc(100% / 3);
	background:none;
	vertical-align:top;
	
	&.drag-target{
		//margin-left:120px;
		//padding-left:100px;

		&:after{
			content:'';
			position:absolute;
			//right:100%;
			left:0;
			top:0;
			width:120px;
			height:100%;
			border:2px dashed #00eda4;
			background:#fff;
			//opacity:0.3;
			z-index:5;
		}
	}
	a{
		display:block;
		width:100%;
		color:#a0a0a0;
		
		&:hover{
			img{
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
	div{
		position:relative;
		margin:0 0 8px 0;
		width:100%;
		padding-bottom:65%;
		overflow:hidden;

		img{
			position:absolute;
			left:0;
			top:0;
			width:100%;
			transition: all .2s ease-in-out;
			height: 100%;
			object-fit: cover;
		}
	}
	h1{
		display:block;
		width:100%;
		font-size:11px;
		line-height:1.3em;
		overflow: hidden;
		text-overflow:ellipsis;
		text-transform:uppercase;
		letter-spacing:0.2px;
	}
	p{
		font-size:9px;
		line-height:1.6em;
		letter-spacing:0.2px;
	}
}
.btn-add-post{
	&:hover{
		span{
			background:#d0d0d0;
		}
	}
	span{
		position:relative;
		display:block;
		width:100%;
		padding-bottom:65%;
		background:#e6e6e6;

		-moz-transition: all .5s ease;
	    -o-transition: all .5s ease;
	    -webkit-transition: all .5s ease;
	    transition: all .5s ease;
	}
	strong{
		position:absolute;
		left:0;
		top:50%;
		margin-top:-15px;
		width:100%;
		height:30px;
		line-height:30px;
		text-align:center;
	}
}

@media screen and (max-width: 1280px){
	
	.article-item,
	.btn-add-post{
		// width:25%;
		width: calc(100% / 3);
	}

}

@media screen and (max-width: 800px){
	
	.article-item,
	.btn-add-post{
		margin:0;
		padding:0;
		// width:33.3333%;
		width: calc(100% / 3);

		div{
			margin:0;
		}
		h1,
		p{
			display:none;
		}
	}

}

@media screen and (max-width: 640px){
	
	.article-item,
	.btn-add-post{
		// width:50%;
		width:100%;
	}

}

@media screen and (max-width: 320px){
	
	.article-item,
	.btn-add-post{
		display:block;
		width:100%;
	}

}




