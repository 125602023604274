.row {
  position:relative;
  width: 100%;
  overflow: hidden
}

.row img,
.row iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  border:0;
}
.row iframe{
  height:100%;
}

.row-fullscreen {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 62.5%;
  overflow: hidden
}

.row-wide {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  overflow: hidden
}

.row-wide10 {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 62.5%;
  overflow: hidden
}

.row-half {
  float: left;
  width: 50%
}

.row-half-content {
  position: relative;
  width: 100%;
  padding-bottom: 125%
}

.row-half-content-smaller{
  position:relative;
  width:100%;
  padding-bottom:100%;
}

.row-half-content-big{
  position:relative;
  width:100%;
  padding-bottom:125%;
}
.row-half-content-dynamic{
  position:relative;
  width:100%;
  padding-bottom:125%;
}
.row-half-full-big{
  position:relative;
  width:100%;
  padding-bottom: 68.749999999999999999999999999998%;
}

.row-half-full {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 62.5%
}

.row-half-half {
  float: left;
  width: 50%
}

.row-half-half-content {
  position: relative;
  width: 100%;
  padding-bottom: 125%
}

.row-video-half {
  float: left;
  width: 50%
}

.row-video-content {
  position: relative;
  width: 100%;
  padding-bottom: 56.249999999999999999999999999999%;

  iframe{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    border:0;
  }
}

.row-one-third {
  float: left;
  width: 35%
}

.row-one-third .row-third-content {
  padding-bottom: 178.57142857%
}

.row-two-third {
  float: left;
  width: 65%
}

.row-two-third .row-third-content {
  padding-bottom: 96.15384615%
}

.row-third-content {
  position: relative;
  width: 100%
}

.row-one-quarter {
  float: left;
  width: 25%
}

.row-one-quarter .row-quarter-content {
  padding-bottom: 250%
}

.row-one-quarter .row-quarter-content-half {
  padding-bottom: 125%
}

.row-three-quarter {
  float: left;
  width: 75%
}

.row-three-quarter .row-quarter-content {
  padding-bottom: 83.33333333%
}

.row-quarter-content,
.row-quarter-content-half {
  position: relative;
  width: 100%
}


@media screen and (max-width: 640px){
  
  .row-half {
    float: none;
    width: 100%
  }
  .row-half-half {
    float: none;
    width: 100%
  }
  .row-video-half {
    float: none;
    width: 100%
  }
  .row-one-third {
    float: none;
    width: 100%
  }
  .row-two-third {
    float: none;
    width: 100%
  }
  .row-one-quarter {
    float: none;
    width: 100%
  }
  .row-three-quarter {
    float: none;
    width: 100%
  }
  .cell-empty{
    display:none;
  }

}



// ---------
// SHOW BORDER
// ---------
@borderW:     20px;
@borderColor: rgba(255,255,255,1);

.br{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  pointer-events:none;

  border-width:@borderW;
  border-style:solid;
  border-color:@borderColor;
}
.show-border{
  //padding-bottom:20px;
  
  .detail-footer{
    margin-top:20px;
  }
  .row{
    padding-left:50px;
    padding-right:50px;

    &.row-nobr{

      &:last-child{
        margin-bottom:-20px;
        padding-bottom:0;
      }
      
    }
  }
  .row-first{
    margin-bottom:@borderW;
    padding-top:110px;
    padding-left:50px;
    padding-right:50px;

    .br{
      border-top:0;
      border-bottom:0;
    }
  }
  .row-nobr{
    padding-top:20px;
    padding-bottom:20px;
    padding-left:0;
    padding-right:0;

    &:first-child{
      padding-top:0;
    }
  }
}

@media screen and (max-width: 1024px){
  
  .br{
    border-width:10px;
  }
  .show-border{
    .row{
      padding-left:30px;
      padding-right:30px;
    }
    .row-first{
      margin-bottom:10px;
      padding-top:80px;

      .br{
        border-top:0;
        border-bottom:0;
      }
    }
    .row-nobr{
      padding-top:10px;
      padding-bottom:10px;
      padding-left:0;
      padding-right:0;

      &:first-child{
        padding-top:0;
      }
    }
  }

}

@media screen and (max-width: 640px){
  
  .show-border{
    .row{
      padding-left:10px;
      padding-right:10px;
    }
    .row-first{
      padding-top:50px;
    }
    .row-nobr{
      padding-top:10px;
      padding-bottom:10px;
      padding-left:0;
      padding-right:0;

      &:first-child{
        padding-top:0;
      }
    }
  }

}


