.section-detail {
  padding: 0;
}

.detail-content, .detail-content-offsetted {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.detail-content h1, .detail-content-offsetted h1 {
  color: #fff;
  z-index: 10;
  padding: 0 14px 24px;
  font-size: 32px;
  line-height: 1.2em;
  position: fixed;
  bottom: 0;
  left: 0;
}

.detail-content h1 button, .detail-content-offsetted h1 button {
  font-size: inherit;
  font-weight: 600;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  letter-spacing: -1.8px;
  text-align: left;
  -o-transition: all .5s ease;
  background: no-repeat;
  outline: 0;
  transition: all .5s;
  display: block;
}

.detail-content h1 button:hover, .detail-content-offsetted h1 button:hover {
  color: #00eda4 !important;
}

.detail-content h1 button:focus, .detail-content-offsetted h1 button:focus {
  outline: 0;
}

.detail-content img, .detail-content-offsetted img {
  max-width: 100%;
}

.detail-content-offsetted {
  margin-bottom: -20px;
}

.detail-footer {
  color: #fff;
  background: #000;
  width: 100%;
  padding: 20px 16px;
}

.detail-footer h1 {
  margin: 0 0 8px;
  font-size: 30px;
  line-height: 1.2em;
}

.detail-footer .p-tagslist span {
  vertical-align: top;
  padding: 0 0 0 12px;
  display: inline-block;
  position: relative;
}

.detail-footer .p-tagslist span:first-child {
  padding-left: 0;
}

.detail-footer .p-tagslist span:first-child:before {
  display: none;
}

.detail-footer .p-tagslist span:before {
  content: "/";
  text-align: center;
  width: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-footer-top p {
  color: #a0a0a0;
  margin: 0 0 3px;
}

.detail-footer-content {
  padding: 10px 0 0;
  font-size: 12px;
  line-height: 1.4em;
  overflow: hidden;
}

.detail-footer-content h3 {
  font-size: 12px;
  line-height: 1.4em;
}

.detail-footer-content p {
  margin: 0 0 18px;
  font-size: 12px;
  line-height: 1.4em;
}

.detail-footer-content p strong {
  font-size: inherit;
  line-height: inherit;
}

.detail-footer-content p a {
  color: #fff;
}

.detail-footer-content ul, .detail-footer-content ol {
  margin: 0 0 18px;
}

.detail-footer-content ul li:before {
  content: "—";
}

.detail-footer-content ol {
  counter-reset: li;
}

.detail-footer-content ol li {
  counter-increment: li;
}

.detail-footer-content ol li:before {
  content: counter(li) ".";
}

.detail-footer-content li {
  margin: 0 0 8px;
  padding-left: 20px;
  font-size: 12px;
  line-height: 1.4em;
  list-style: none;
  position: relative;
}

.detail-footer-content li:before {
  font-size: 12px;
  line-height: 1.4em;
  position: absolute;
  top: 0;
  left: 4px;
}

.detail-footer-content-left {
  float: left;
  width: 360px;
  padding-right: 60px;
}

.detail-footer-content-right {
  float: left;
  width: 300px;
}

.detail-footer-btns {
  padding: 40px 0;
}

.detail-footer-btns button {
  color: #fff;
  text-align: left;
  vertical-align: top;
  -o-transition: all .5s ease;
  background: none;
  outline: 0;
  width: 46px;
  height: 40px;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  transition: all .5s;
  display: inline-block;
}

.detail-footer-btns button:hover {
  color: #00eda4;
}

.detail-footer-btns button:focus {
  outline: 0;
}

.detail-footer-btns .btn-mobile-prev, .detail-footer-btns .btn-mobile-next {
  vertical-align: top;
  text-indent: -9999px;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 12px;
  outline: 0;
  width: 40px;
  height: 40px;
  display: none;
  position: relative;
}

.detail-footer-btns .btn-mobile-prev:after, .detail-footer-btns .btn-mobile-next:after {
  content: "";
  opacity: 0;
  -o-transition: all .5s ease;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-footer-btns .btn-mobile-prev:hover:after, .detail-footer-btns .btn-mobile-next:hover:after {
  opacity: 1;
}

.detail-footer-btns .btn-mobile-prev {
  background-image: url("ico-arrow-prev-white.99bbd4ec.svg");
}

.detail-footer-btns .btn-mobile-prev:after {
  background-image: url("ico-arrow-prev.2dd0b3be.svg");
}

.detail-footer-btns .btn-mobile-next {
  background-image: url("ico-arrow-next-white.65643935.svg");
}

.detail-footer-btns .btn-mobile-next:after {
  background-image: url("ico-arrow-next.354ca9f2.svg");
}

.detail-btn-prev, .detail-btn-next {
  opacity: 0;
  -o-transition: all .5s ease;
  width: 230px;
  height: 280px;
  margin-top: -140px;
  line-height: 280px;
  transition: all .5s;
  position: fixed;
  top: 50%;
}

.detail-btn-prev:hover, .detail-btn-next:hover {
  opacity: 1;
  visibility: visible;
}

.detail-btn-prev:before, .detail-btn-next:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
}

.detail-btn-prev p, .detail-btn-next p {
  color: #00eda4;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.4em;
  display: inline-block;
}

.detail-btn-prev {
  cursor: url("ico-arrow-prev.2dd0b3be.svg") 12 12, pointer;
  padding-left: 50px;
  left: 0;
}

.detail-btn-prev:before {
  background-image: url("ico-arrow-prev.2dd0b3be.svg");
  left: 0;
}

.detail-btn-next {
  text-align: right;
  cursor: url("ico-arrow-next.354ca9f2.svg") 12 12, pointer;
  padding-right: 50px;
  right: 0;
}

.detail-btn-next:before {
  background-image: url("ico-arrow-next.354ca9f2.svg");
  right: 0;
}

@media screen and (width <= 1024px) {
  .detail-footer-btns .btn-mobile-prev, .detail-footer-btns .btn-mobile-next {
    display: inline-block;
  }

  .detail-btn-prev, .detail-btn-next {
    display: none;
  }
}

@media screen and (width <= 760px) {
  .detail-content h1 {
    font-size: 26px;
  }

  .detail-content h1 button {
    letter-spacing: -1px;
  }

  .detail-footer-content-left {
    width: 65%;
  }

  .detail-footer-content-right {
    width: 35%;
  }
}

@media screen and (width <= 640px) {
  .detail-content h1 {
    font-size: 22px;
  }

  .detail-content h1 button {
    letter-spacing: -.4px;
  }

  .detail-footer h1 {
    font-size: 20px;
  }

  .detail-footer-content-left {
    float: none;
    width: 100%;
    padding-right: 0;
  }

  .detail-footer-content-right {
    float: none;
    width: 100%;
  }
}

.row {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.row img, .row iframe {
  border: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.row iframe {
  height: 100%;
}

.row-fullscreen {
  width: 100%;
  height: auto;
  padding-bottom: 62.5%;
  position: relative;
  overflow: hidden;
}

.row-wide {
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.row-wide10 {
  width: 100%;
  height: auto;
  padding-bottom: 62.5%;
  position: relative;
  overflow: hidden;
}

.row-half {
  float: left;
  width: 50%;
}

.row-half-content {
  width: 100%;
  padding-bottom: 125%;
  position: relative;
}

.row-half-content-smaller {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.row-half-content-big, .row-half-content-dynamic {
  width: 100%;
  padding-bottom: 125%;
  position: relative;
}

.row-half-full-big {
  width: 100%;
  padding-bottom: 68.75%;
  position: relative;
}

.row-half-full {
  width: 100%;
  height: auto;
  padding-bottom: 62.5%;
  position: relative;
}

.row-half-half {
  float: left;
  width: 50%;
}

.row-half-half-content {
  width: 100%;
  padding-bottom: 125%;
  position: relative;
}

.row-video-half {
  float: left;
  width: 50%;
}

.row-video-content {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.row-video-content iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.row-one-third {
  float: left;
  width: 35%;
}

.row-one-third .row-third-content {
  padding-bottom: 178.571%;
}

.row-two-third {
  float: left;
  width: 65%;
}

.row-two-third .row-third-content {
  padding-bottom: 96.1539%;
}

.row-third-content {
  width: 100%;
  position: relative;
}

.row-one-quarter {
  float: left;
  width: 25%;
}

.row-one-quarter .row-quarter-content {
  padding-bottom: 250%;
}

.row-one-quarter .row-quarter-content-half {
  padding-bottom: 125%;
}

.row-three-quarter {
  float: left;
  width: 75%;
}

.row-three-quarter .row-quarter-content {
  padding-bottom: 83.3333%;
}

.row-quarter-content, .row-quarter-content-half {
  width: 100%;
  position: relative;
}

@media screen and (width <= 640px) {
  .row-half, .row-half-half, .row-video-half, .row-one-third, .row-two-third, .row-one-quarter, .row-three-quarter {
    float: none;
    width: 100%;
  }

  .cell-empty {
    display: none;
  }
}

.br {
  pointer-events: none;
  border: 20px solid #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.show-border .detail-footer {
  margin-top: 20px;
}

.show-border .row {
  padding-left: 50px;
  padding-right: 50px;
}

.show-border .row.row-nobr:last-child {
  margin-bottom: -20px;
  padding-bottom: 0;
}

.show-border .row-first {
  margin-bottom: 20px;
  padding-top: 110px;
  padding-left: 50px;
  padding-right: 50px;
}

.show-border .row-first .br {
  border-top: 0;
  border-bottom: 0;
}

.show-border .row-nobr {
  padding: 20px 0;
}

.show-border .row-nobr:first-child {
  padding-top: 0;
}

@media screen and (width <= 1024px) {
  .br {
    border-width: 10px;
  }

  .show-border .row {
    padding-left: 30px;
    padding-right: 30px;
  }

  .show-border .row-first {
    margin-bottom: 10px;
    padding-top: 80px;
  }

  .show-border .row-first .br {
    border-top: 0;
    border-bottom: 0;
  }

  .show-border .row-nobr {
    padding: 10px 0;
  }

  .show-border .row-nobr:first-child {
    padding-top: 0;
  }
}

@media screen and (width <= 640px) {
  .show-border .row {
    padding-left: 10px;
    padding-right: 10px;
  }

  .show-border .row-first {
    padding-top: 50px;
  }

  .show-border .row-nobr {
    padding: 10px 0;
  }

  .show-border .row-nobr:first-child {
    padding-top: 0;
  }
}

.ReactCrop {
  cursor: crosshair;
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__image {
  touch-action: none;
  max-width: 100%;
  display: block;
}

.ReactCrop__crop-selection {
  box-sizing: border-box;
  cursor: move;
  touch-action: none;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 0 9999em #00000080;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #fff, 0 0 0 9999em #00000080;
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after, .ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  content: "";
  background-color: #fff6;
  display: block;
  position: absolute;
}

.ReactCrop__rule-of-thirds-vt:before, .ReactCrop__rule-of-thirds-vt:after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt:before {
  left: 33.3333%;
}

.ReactCrop__rule-of-thirds-vt:after {
  left: 66.6667%;
}

.ReactCrop__rule-of-thirds-hz:before, .ReactCrop__rule-of-thirds-hz:after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz:before {
  top: 33.3333%;
}

.ReactCrop__rule-of-thirds-hz:after {
  top: 66.6667%;
}

.ReactCrop__drag-handle {
  position: absolute;
}

.ReactCrop__drag-handle:after {
  content: "";
  box-sizing: border-box;
  background-color: #0003;
  border: 1px solid #ffffffb3;
  outline: 1px solid #0000;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
}

.ReactCrop .ord-nw {
  cursor: nw-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 0;
}

.ReactCrop .ord-nw:after {
  top: 0;
  left: 0;
}

.ReactCrop .ord-n {
  cursor: n-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.ReactCrop .ord-n:after {
  top: 0;
}

.ReactCrop .ord-ne {
  cursor: ne-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 0;
  right: 0;
}

.ReactCrop .ord-ne:after {
  top: 0;
  right: 0;
}

.ReactCrop .ord-e {
  cursor: e-resize;
  margin-top: -5px;
  margin-right: -5px;
  top: 50%;
  right: 0;
}

.ReactCrop .ord-e:after {
  right: 0;
}

.ReactCrop .ord-se {
  cursor: se-resize;
  margin-bottom: -5px;
  margin-right: -5px;
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-se:after {
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-s {
  cursor: s-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.ReactCrop .ord-s:after {
  bottom: 0;
}

.ReactCrop .ord-sw {
  cursor: sw-resize;
  margin-bottom: -5px;
  margin-left: -5px;
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-sw:after {
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-w {
  cursor: w-resize;
  margin-top: -5px;
  margin-left: -5px;
  top: 50%;
  left: 0;
}

.ReactCrop .ord-w:after {
  left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  width: 100%;
  height: 6px;
  margin-top: -3px;
  top: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-e {
  width: 6px;
  height: 100%;
  margin-right: -3px;
  top: 0;
  right: 0;
}

.ReactCrop__drag-bar.ord-s {
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
  bottom: 0;
  left: 0;
}

.ReactCrop__drag-bar.ord-w {
  width: 6px;
  height: 100%;
  margin-left: -3px;
  top: 0;
  left: 0;
}

.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n, .ReactCrop .ord-e, .ReactCrop .ord-s, .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

.article-item, .btn-add-post {
  vertical-align: top;
  background: none;
  width: 33.3333%;
  margin: 0 0 18px;
  padding: 0 4px;
  display: inline-block;
  position: relative;
}

.article-item.drag-target:after, .btn-add-post.drag-target:after {
  content: "";
  z-index: 5;
  background: #fff;
  border: 2px dashed #00eda4;
  width: 120px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.article-item a, .btn-add-post a {
  color: #a0a0a0;
  width: 100%;
  display: block;
}

.article-item a:hover img, .btn-add-post a:hover img {
  transform: scale(1.1);
}

.article-item div, .btn-add-post div {
  width: 100%;
  margin: 0 0 8px;
  padding-bottom: 65%;
  position: relative;
  overflow: hidden;
}

.article-item div img, .btn-add-post div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.article-item h1, .btn-add-post h1 {
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: .2px;
  width: 100%;
  font-size: 11px;
  line-height: 1.3em;
  display: block;
  overflow: hidden;
}

.article-item p, .btn-add-post p {
  letter-spacing: .2px;
  font-size: 9px;
  line-height: 1.6em;
}

.btn-add-post:hover span {
  background: #d0d0d0;
}

.btn-add-post span {
  -o-transition: all .5s ease;
  background: #e6e6e6;
  width: 100%;
  padding-bottom: 65%;
  transition: all .5s;
  display: block;
  position: relative;
}

.btn-add-post strong {
  text-align: center;
  width: 100%;
  height: 30px;
  margin-top: -15px;
  line-height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
}

@media screen and (width <= 1280px) {
  .article-item, .btn-add-post {
    width: 33.3333%;
  }
}

@media screen and (width <= 800px) {
  .article-item, .btn-add-post {
    width: 33.3333%;
    margin: 0;
    padding: 0;
  }

  .article-item div, .btn-add-post div {
    margin: 0;
  }

  .article-item h1, .btn-add-post h1, .article-item p, .btn-add-post p {
    display: none;
  }
}

@media screen and (width <= 640px) {
  .article-item, .btn-add-post {
    width: 100%;
  }
}

@media screen and (width <= 320px) {
  .article-item, .btn-add-post {
    width: 100%;
    display: block;
  }
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 62.5%;
}

a:focus, a:hover, a:active {
  outline: 0;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  -ms-interpolation-mode: bicubic;
}

button, input, select, textarea {
  vertical-align: middle;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

a, area, button, [role="button"], input, label, select, summary, textarea {
  touch-action: manipulation;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-Regular-Web.fb10ca6f.woff2") format("woff2"), url("Graphik-Regular-Web.69e2cf39.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik Web;
  src: url("Graphik-Semibold-Web.d5619ee4.woff2") format("woff2"), url("Graphik-Semibold-Web.f4672aed.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

html {
  box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #000;
  background: #fff;
  margin: 0;
  font-family: Graphik Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  line-height: 1;
}

.disable-scroll {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

a {
  color: #000;
  text-decoration-skip: ink;
  -o-transition: all .5s ease;
  text-decoration: none;
  transition: all .5s;
}

a:hover {
  text-decoration: none;
  color: #00eda4 !important;
}

header {
  z-index: 10;
  background: #fff;
  width: 100%;
  padding: 6px 14px 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

header h1 {
  float: left;
  text-align: left;
  margin: 0 20px 0 0;
}

header a {
  color: inherit;
  letter-spacing: -2.4px;
  font-size: 64px;
  font-weight: 600;
  line-height: 1em;
}

header ul {
  float: right;
  list-style: none;
  overflow: hidden;
}

header li {
  float: left;
}

header a:hover svg {
  fill: #00eda4 !important;
}

header .social-item {
  margin: 0 0 0 6px;
}

header svg {
  -o-transition: all .5s ease;
  width: 36px;
  height: 36px;
  transition: all .5s;
}

@media screen and (width <= 1280px) {
  header h1 {
    margin: 0 14px 0 0;
  }

  header a {
    font-size: 48px;
  }

  header svg {
    width: 26px;
    height: 26px;
  }
}

@media screen and (width <= 640px) {
  header h1 {
    margin: 0 10px 0 0;
  }

  header a {
    letter-spacing: -1px;
    font-size: 24px;
  }

  header svg {
    width: 24px;
    height: 24px;
    position: relative;
    top: 1px;
  }
}

.headerAuth {
  vertical-align: top;
  padding: 20px 0 0 20px;
  display: inline-block;
}

.headerAuth span {
  vertical-align: top;
  font-size: 16px;
  line-height: 28px;
  display: inline-block;
}

.headerAuth button {
  vertical-align: top;
  border: 0;
  border-radius: 4px;
  margin: 0 0 0 20px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
}

.headerProjectEdit {
  color: #000;
  background: #00eda4;
}

.headerProjectEdit:hover {
  color: #00eda4;
  background: #000;
}

.headerAuthLogout {
  color: #fff;
  background: #000;
}

.headerAuthLogout:hover {
  color: #fff;
  background: red;
}

@media screen and (width <= 1280px) {
  .headerAuth {
    padding-top: 8px;
  }

  .headerAuth span {
    font-size: 14px;
  }

  .headerAuth button {
    margin: 0 0 0 12px;
  }
}

@media screen and (width <= 900px) {
  .headerAuth span {
    font-size: 14px;
    line-height: 22px;
  }

  .headerAuth button {
    margin: 0 0 0 12px;
    padding: 4px 6px;
    font-size: 11px;
    line-height: 14px;
  }
}

@media screen and (width <= 640px) {
  .headerAuth {
    padding-top: 2px;
  }

  .headerAuth span {
    font-size: 12px;
    line-height: 20px;
  }

  .headerAuth button {
    margin: 0 0 0 8px;
    padding: 2px 6px;
  }
}

@media screen and (width <= 500px) {
  .headerAuth {
    padding-left: 10px;
  }

  .headerAuth span {
    display: none;
  }
}

@media screen and (width <= 400px) {
  .headerAuth {
    padding-left: 6px;
  }

  .headerAuth button {
    margin: 0 0 0 6px;
    padding: 4px;
  }
}

.is-dragging header {
  pointer-events: none;
}

nav {
  width: 100%;
  padding: 0 0 0 2px;
  overflow: hidden;
}

nav ul {
  width: 100%;
  list-style: none;
}

nav li {
  float: left;
  margin: 0 20px 0 0;
}

nav a {
  letter-spacing: -1px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.24em;
}

@media screen and (width <= 640px) {
  nav {
    display: none;
  }
}

.current-category-link {
  color: #00eda4;
}

section {
  padding: 176px 10px 0;
}

@media screen and (width <= 640px) {
  section {
    padding-top: 50px;
  }
}

.post-detail header {
  background: none;
}

.post-detail nav {
  display: none;
}

.post-detail .row {
  cursor: url("close-cursor.da0b30aa.png") 12 12, pointer;
}

.detail-footer {
  z-index: 10;
  position: relative;
}

.btn-edit, .btn-del {
  vertical-align: top;
  border-radius: 2px;
  height: 24px;
  margin: 4px 2px 4px 0;
  padding: 0 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
}

.btn-edit {
  color: #fff;
  background: #000;
}

.btn-edit:hover {
  color: #000;
  background: #00eda4;
}

.btn-del {
  color: red;
  background: none;
}

.btn-del:hover {
  color: #fff;
  background: red;
}

.post-edit {
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  background: #000c;
  width: 100%;
  height: 100%;
  padding: 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.post-edit-content {
  background: #fff;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px;
}

.post-edit-content h1, .post-edit-content h2 {
  letter-spacing: -.4px;
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6em;
}

.post-edit-content h2 {
  margin: 10px 0 6px;
}

.post-edit-content .article-tab-content {
  padding-left: 0;
}

.post-edit-header {
  position: relative;
}

.post-edit-header button {
  letter-spacing: -.4px;
  text-align: center;
  outline: 0;
  min-width: 35px;
  height: 35px;
  font-size: 12px;
  font-weight: 600;
  line-height: 35px;
}

.post-edit-header button:focus {
  outline: 0;
}

.post-edit-header .post-edit-language-current {
  color: #00eda4;
  background: #000;
}

.post-edit-header .btn-save {
  color: #fff;
  background: #000;
  border-radius: 4px;
  width: auto;
  height: 30px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 2px;
  right: 45px;
}

.post-edit-header .btn-save:hover {
  color: #000;
  background: #00eda4;
}

.post-btn-cancel {
  text-align: center;
  cursor: pointer;
  background: #f5f5f5;
  width: 35px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  position: absolute;
  top: 0;
  right: 0;
}

.post-btn-cancel:hover {
  background: #000;
}

.item-post-image {
  margin: 0 0 8px;
}

.item-post-image-label {
  letter-spacing: -.4px;
  vertical-align: top;
  min-width: 90px;
  height: 28px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3em;
  display: inline-block;
}

.item-post-image-label span {
  letter-spacing: 0;
  font-size: 9px;
  font-weight: 400;
  line-height: 1.3em;
  display: block;
}

.post-edit-image {
  vertical-align: top;
  cursor: pointer;
  background: silver;
  width: 100px;
  min-height: 50px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.post-edit-image > img {
  float: left;
  width: 100%;
  height: auto;
}

.post-edit-image .btn-imageedit {
  color: #fff;
  text-indent: -9999px;
  z-index: 99;
  background: #fff url("iconfinder_ic_crop_48px_352293.6f4a371b.svg") center / 16px no-repeat;
  width: 20px;
  height: 20px;
  font-size: 9px;
  line-height: 22px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.post-edit-image .btn-imageedit:hover {
  background-color: #00eda4;
}

.post-edit-params {
  border-bottom: 1px solid #000;
  margin: 0 0 10px;
  padding: 0 0 15px;
}

.post-edit-params .p-slug {
  margin: -4px 0 6px;
  padding: 0 0 0 93px;
  font-size: 10px;
}

.post-edit-params .p-markdowninfo {
  text-align: right;
  padding: 0 0 4px;
  font-size: 11px;
}

.item-input, .item-input-color, .item-textarea {
  margin: 0 0 8px;
  display: block;
}

.item-input label, .item-input-color label, .item-textarea label {
  letter-spacing: -.4px;
  vertical-align: top;
  min-width: 90px;
  height: 28px;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  display: inline-block;
}

.item-input input, .item-input-color input, .item-textarea input, .item-input textarea, .item-input-color textarea, .item-textarea textarea {
  border: 1px solid #ccc;
  width: 300px;
  height: 28px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 26px;
}

.item-input textarea, .item-input-color textarea, .item-textarea textarea {
  width: 100%;
  max-width: 100%;
  height: 110px;
  min-height: 110px;
  padding: 6px 8px;
  line-height: 1.5em;
}

.item-input-color label {
  min-width: 120px;
}

.item-input-color input {
  cursor: pointer;
  width: 28px;
  padding: 0;
}

.item-textarea label {
  margin: 0;
  display: block;
}

.item-textarea textarea {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.item-radio {
  width: 100%;
  margin: 0 0 6px;
  font-size: 12px;
  line-height: 12px;
  display: block;
}

.item-radio input {
  vertical-align: top;
}

.post-edit-selects {
  margin: 0 0 20px;
  overflow: hidden;
}

.item-third {
  float: left;
  width: 33%;
}

.post-edit-save {
  text-align: right;
  border-top: 1px solid #000;
  width: auto;
  margin: 0 -10px;
  padding: 10px;
}

.post-edit-save button {
  color: #fff;
  vertical-align: top;
  background: #000;
  border-radius: 4px;
  height: 30px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

.post-edit-save button:hover {
  color: #000;
  background: #00eda4;
}

.post-edit-save button:disabled, .post-edit-save button:disabled:hover {
  color: #fff;
  cursor: default;
  background: #bbb;
}

.post-edit-save .btn-cancel {
  vertical-align: top;
  cursor: pointer;
  height: 30px;
  margin: 0 10px 0 0;
  font-size: 10px;
  line-height: 30px;
  display: inline-block;
}

.post-image-row {
  overflow: hidden;
}

.admin-row {
  float: left;
  text-align: center;
  background: #f3f3f3;
  width: 300px;
}

.admin-row-item {
  margin: 0 0 4px;
}

.admin-row-item .row {
  overflow: visible;
}

.admin-row-item .row:after {
  content: "";
  clear: both;
  display: table;
}

.admin-row-item .row-fullscreen, .admin-row-item .row-wide, .admin-row-item .row-wide10, .admin-row-item .row-third-content, .admin-row-item .row-quarter-content, .admin-row-item .row-half-content, .admin-row-item .row-half-content-smaller, .admin-row-item .row-half-content-big, .admin-row-item .row-half-full, .admin-row-item .row-half-full-big, .admin-row-item .row-half-half-content, .admin-row-item .row-video-content, .admin-row-item .row-quarter-content-half {
  cursor: pointer;
  background: #c5c5c5;
  overflow: hidden;
}

.admin-row-item .row-fullscreen:hover, .admin-row-item .row-wide:hover, .admin-row-item .row-wide10:hover, .admin-row-item .row-third-content:hover, .admin-row-item .row-quarter-content:hover, .admin-row-item .row-half-content:hover, .admin-row-item .row-half-content-smaller:hover, .admin-row-item .row-half-content-big:hover, .admin-row-item .row-half-full:hover, .admin-row-item .row-half-full-big:hover, .admin-row-item .row-half-half-content:hover, .admin-row-item .row-video-content:hover, .admin-row-item .row-quarter-content-half:hover {
  background: #000;
}

.admin-row-item .row-fullscreen:hover .admin-row-item-span, .admin-row-item .row-wide:hover .admin-row-item-span, .admin-row-item .row-wide10:hover .admin-row-item-span, .admin-row-item .row-third-content:hover .admin-row-item-span, .admin-row-item .row-quarter-content:hover .admin-row-item-span, .admin-row-item .row-half-content:hover .admin-row-item-span, .admin-row-item .row-half-content-smaller:hover .admin-row-item-span, .admin-row-item .row-half-content-big:hover .admin-row-item-span, .admin-row-item .row-half-full:hover .admin-row-item-span, .admin-row-item .row-half-full-big:hover .admin-row-item-span, .admin-row-item .row-half-half-content:hover .admin-row-item-span, .admin-row-item .row-video-content:hover .admin-row-item-span, .admin-row-item .row-quarter-content-half:hover .admin-row-item-span {
  color: #00eda4;
}

.admin-row-item .btn-remove, .admin-row-item .btn-imageedit {
  color: #fff;
  text-align: center;
  z-index: 99;
  background: #000;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.admin-row-item .btn-remove:hover {
  background: red;
}

.admin-row-item .btn-imageedit {
  text-indent: -9999px;
  background: #fff url("iconfinder_ic_crop_48px_352293.6f4a371b.svg") center / 16px no-repeat;
  font-size: 9px;
  line-height: 22px;
  top: auto;
  bottom: 0;
}

.admin-row-item .btn-imageedit:hover {
  background-color: #00eda4;
}

.admin-row-item-span {
  color: #000;
  text-align: center;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
}

.border-label {
  text-align: left;
  padding: 2px 6px;
  line-height: 20px;
  display: block;
}

.border-label input {
  position: relative;
  top: -1px;
}

.admin-row-action {
  text-align: center;
  background: #000;
  width: 18px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
}

.admin-row-action button {
  color: #000;
  background: #f5f5f5;
  width: 100%;
  height: 18px;
  font-size: 14px;
  font-weight: 600;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.admin-row-action button:hover {
  color: #fff;
  background: red;
}

.admin-row-action .btn-switch {
  height: 24px;
  font-size: 12px;
  top: 24px;
}

.admin-row-action .btn-switch:hover {
  color: #fff;
  background: #000;
}

.admin-row-action .btn-draggable {
  color: #000;
  text-align: center;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  background: #00eda4;
  width: 100%;
  height: 24px;
  font-size: 11px;
  line-height: 24px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.admin-row-action .btn-draggable:hover {
  color: #fff;
  background: #000;
}

.btn-add-row {
  color: #000;
  vertical-align: top;
  text-align: center;
  background: #00eda4;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 20px 0;
  font-size: 18px;
  line-height: 40px;
  display: inline-block;
}

.btn-add-row:hover {
  color: #fff;
  background: #000;
}

.admin-add-row-list {
  text-align: center;
  width: 100%;
  list-style: none;
}

.admin-add-row-list li {
  width: 160px;
  margin: 0 auto 4px;
}

.admin-add-row-list button {
  width: 100%;
  display: block;
}

.admin-add-row-list button:hover {
  background: #00eda4;
}

.files-editor {
  float: left;
  width: 200px;
  margin: 0 0 0 40px;
  padding: 0 0 20px;
}

.files-editor .btn-upload {
  text-align: left;
  cursor: pointer;
  background: #00eda4;
  outline: 0;
  width: 100%;
  margin: 0 0 20px;
  padding: 10px 10px 9px 36px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  display: block;
  position: relative;
}

.files-editor .btn-upload:before, .files-editor .btn-upload:after {
  content: "";
  background: url("ico-picture-dark.fa1a435d.svg") 50% 48% / auto 18px no-repeat;
  width: 36px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.files-editor .btn-upload:after {
  visibility: hidden;
  background-image: url("ico-picture-white.b9629639.svg");
}

.files-editor .btn-upload:focus {
  outline: 0;
}

.files-editor .btn-upload:hover {
  color: #fff;
  background: #000;
}

.files-editor .btn-upload:hover:before {
  visibility: hidden;
}

.files-editor .btn-upload:hover:after {
  visibility: visible;
}

.files-editor .btn-remove {
  cursor: pointer;
  z-index: 1;
  background: #fff;
  width: 20px;
  height: 20px;
  font-weight: 700;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.files-editor .btn-remove:hover {
  background: #00eda4;
}

.files-editor div {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  width: 100%;
  height: auto;
  margin: 10px 0 0;
  position: relative;
  overflow: hidden;
}

.files-editor div p {
  color: #fff;
  background: #000;
  padding: 0 6px;
  font-size: 10px;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.files-editor img {
  float: left;
  width: 100%;
  height: auto;
}

.files-editor-video-file {
  background: #000;
}

.files-editor-video-file iframe {
  float: left;
  border: 0;
  width: 100%;
  height: auto;
}

.article .article-tab-content button {
  color: #fff;
  vertical-align: top;
  cursor: pointer;
  -o-transition: all .5s ease;
  background: #000;
  border: 0;
  border-radius: 2px;
  height: 24px;
  margin: 4px 2px 4px 0;
  padding: 0 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  transition: all .5s;
  display: inline-block;
}

.article .article-tab-content button:hover {
  color: #000;
  background: #00eda4;
}

.article .btn-add-new {
  color: #333;
  vertical-align: top;
  -o-transition: all .5s ease;
  background: #00eda4;
  margin: 0 0 20px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  transition: all .5s;
  display: inline-block;
}

.article .btn-add-new:hover {
  color: #fff;
  background: #000;
}

.article-tab {
  max-width: 800px;
  margin: 0 0 40px -10px;
  padding-right: 4px;
}

.article-tab-head {
  cursor: pointer;
  border-top: 1px solid #000;
  margin: 0 0 10px 14px;
  padding: 2px 0;
  display: block;
}

.article-tab-head h2 {
  vertical-align: top;
  padding: 0 28px 0 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.24em;
  display: inline-block;
  position: relative;
}

.article-tab-head h2:after {
  content: "";
  -o-transition: all .4s ease;
  background: url("ico-arrow-top.8ead5a2e.svg") 100% / contain no-repeat;
  width: 20px;
  height: 14px;
  margin-top: -8px;
  transition: all .4s;
  position: absolute;
  top: 50%;
  right: 0;
}

.article-tab-head button {
  float: right;
  text-indent: -9999px;
  cursor: pointer;
  -o-transition: all .5s ease;
  background: #ccc;
  border: 0;
  outline: 0;
  width: 30px;
  height: 26px;
  margin: 0 0 0 4px;
  transition: all .5s;
  position: relative;
}

.article-tab-head button:focus {
  outline: 0;
}

.article-tab-head button:before {
  content: "";
  background: url("ico-arrow-prev-white.99bbd4ec.svg") center / 10px no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.article-tab-head button:hover {
  background: #00eda4;
}

.article-tab-head .btn-up:before {
  transform: rotate(90deg);
}

.article-tab-head .btn-down:before {
  transform: rotate(-90deg);
}

.article-tab-head-act {
  cursor: pointer;
  border-top: 1px solid #000;
  margin: 0 0 10px 14px;
  padding: 2px 0;
  display: block;
}

.article-tab-head-act h2 {
  vertical-align: top;
  padding: 0 28px 0 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.24em;
  display: inline-block;
  position: relative;
}

.article-tab-head-act h2:after {
  content: "";
  -o-transition: all .4s ease;
  background: url("ico-arrow-top.8ead5a2e.svg") 100% / contain no-repeat;
  width: 20px;
  height: 14px;
  margin-top: -8px;
  transition: all .4s;
  position: absolute;
  top: 50%;
  right: 0;
}

.article-tab-head-act button {
  float: right;
  text-indent: -9999px;
  cursor: pointer;
  -o-transition: all .5s ease;
  background: #ccc;
  border: 0;
  outline: 0;
  width: 30px;
  height: 26px;
  margin: 0 0 0 4px;
  transition: all .5s;
  position: relative;
}

.article-tab-head-act button:focus {
  outline: 0;
}

.article-tab-head-act button:before {
  content: "";
  background: url("ico-arrow-prev-white.99bbd4ec.svg") center / 10px no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.article-tab-head-act button:hover {
  background: #00eda4;
}

.article-tab-head-act .btn-up:before {
  transform: rotate(90deg);
}

.article-tab-head-act .btn-down:before {
  transform: rotate(-90deg);
}

.article-tab-head-act h2:after {
  margin-top: -7px;
  transform: rotate(-180deg);
}

.article-tab-content {
  padding: 0 0 0 14px;
  transition: height .3s;
  overflow: hidden;
}

.article-tab-content h3 {
  margin: 0 0 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}

.article-tab-content p {
  margin: 0 0 20px;
  font-size: 12px;
  line-height: 1.4em;
}

.article-tab-content ul, .article-tab-content ol {
  margin: 0 0 20px;
}

.article-tab-content ul li:before {
  content: "—";
}

.article-tab-content ol {
  counter-reset: li;
}

.article-tab-content ol li {
  counter-increment: li;
}

.article-tab-content ol li:before {
  content: counter(li) ".";
}

.article-tab-content li {
  margin: 0 0 8px;
  padding-left: 20px;
  font-size: 12px;
  line-height: 1.4em;
  list-style: none;
  position: relative;
}

.article-tab-content li:before {
  font-size: 12px;
  line-height: 1.4em;
  position: absolute;
  top: 0;
  left: 4px;
}

.article-tab-content .zapis-v-rejstriku {
  max-width: 50%;
  margin-bottom: 14px;
}

.article-tab-content a {
  color: #7d7d7d;
}

.article-tab-content .person-name a {
  color: #000;
}

.article-tab-content-collapsed {
  transform: scaleY(0);
}

.column-list {
  font-size: 0;
  line-height: 0;
}

.column-list .column {
  vertical-align: top;
  width: 33%;
  margin: 0 0 14px;
  display: inline-block;
}

.column-list .column p {
  margin: 0;
}

.column-list .column-third {
  width: 33%;
}

.column-list .column-half {
  width: 50%;
}

.column-list .column-full {
  width: 100%;
  display: block;
}

@media screen and (width <= 650px) {
  .column-list .column, .column-list .column-third, .column-list .column-half {
    width: 50%;
  }

  .column-list .column-full {
    width: 100%;
  }
}

@media screen and (width <= 500px) {
  .column-list .column {
    width: 100%;
    display: block;
  }

  .column-list .column-third, .column-list .column-half, .column-list .column-full {
    width: 100%;
  }
}

.item-third .people-list {
  margin: 5px 0 0;
  padding: 0 0 0 23px;
  line-height: 18px;
  display: block;
  position: relative;
}

.item-third .people-list span {
  text-align: center;
  cursor: pointer;
  background: #e6e6e6;
  width: 18px;
  height: 18px;
  font-size: 10px;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.item-third .people-list span:hover {
  color: #fff;
  background: #000;
}

.post-edit-content .article-tab {
  width: auto;
  margin: 0 -10px;
  padding: 0 10px;
}

.post-edit-content .article-tab-head, .post-edit-content .article-tab-head-act {
  margin: 0;
  padding: 0;
}

.post-edit-content .article-tab-head h2, .post-edit-content .article-tab-head-act h2 {
  margin: 0;
  padding: 0 22px 0 0;
  font-size: 16px;
  line-height: 40px;
}

.post-edit-content .article-tab-head h2:after, .post-edit-content .article-tab-head-act h2:after {
  background-size: 12px;
  width: 12px;
}

.article-tab-content .article-column-2 {
  -moz-columns: 0 2;
  -webkit-columns: 0 2;
  columns: 0 2;
}

.article-tab-content .article-column-2 p {
  padding-right: 10px;
  font-size: 16px;
  line-height: 1.3em;
}

.article-tab-content .article-column-2 li {
  padding-left: 28px;
  font-size: 16px;
  line-height: 1.3em;
}

.article-tab-content .article-column-2 li:before {
  font-size: 16px;
  line-height: 1.3em;
}

@media screen and (width <= 640px) {
  .article-tab-content .article-column-2 {
    -moz-columns: 0 1;
    -webkit-columns: 0 1;
    columns: 0 1;
  }
}

.article-tab-content .article-contact-list ul {
  margin: 0;
  font-size: 0;
  line-height: 0;
}

.article-tab-content .article-contact-list li {
  vertical-align: top;
  width: 33%;
  margin: 0 0 10px;
  padding-left: 0;
  padding-right: 20px;
  font-size: 12px;
  line-height: 1.4em;
  display: inline-block;
  position: relative;
}

.article-tab-content .article-contact-list li:before {
  display: none;
}

.article-tab-content .article-contact-list li.drag-target:after {
  content: "";
  z-index: 5;
  background: #fff;
  border: 2px dashed #00eda4;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.article-tab-content .article-contact-list li button {
  display: block;
}

.article-tab-content .article-contact-list h3 {
  margin: 0 0 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  display: block;
}

.article-tab-content .article-contact-list .person-name {
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.article-tab-content .article-contact-list .person-name:hover {
  color: #00eda4;
}

.article-tab-content .article-contact-list .position {
  font-size: 12px;
  line-height: 1.4em;
  display: block;
}

.article-tab-content .article-contact-list .person-detail {
  background: #000;
  margin: 0 0 0 -14px;
  padding: 16px 14px;
}

.article-tab-content .article-contact-list .person-detail h3, .article-tab-content .article-contact-list .person-detail p, .article-tab-content .article-contact-list .person-detail li {
  color: #fff;
}

.article-tab-content .article-contact-list .person-detail h3 {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: normal;
}

.article-tab-content .article-contact-list .person-detail p {
  max-width: 65%;
}

.article-tab-content .article-contact-list .person-detail ul {
  margin-top: 20px;
}

.article-tab-content .article-contact-list .person-detail li {
  margin-bottom: 8px;
}

.article-tab-content .article-contact-list .person-detail li a {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 1.4em;
  display: block;
  overflow: hidden;
}

.article-tab-content .article-contact-list .person-detail li a:hover {
  color: #00eda4;
}

.article-tab-content .article-contact-list .person-detail button {
  color: #fff;
  text-align: left;
  background: #000;
  margin: 20px 0 0;
  font-size: 40px;
  font-weight: normal;
}

.article-tab-content .article-contact-list .person-detail button:focus {
  border: 0;
  outline: 0;
}

.article-tab-content .article-contact-list .person-detail button svg {
  width: 24px;
  height: 24px;
}

@media screen and (width <= 650px) {
  .article-tab-content .article-contact-list .person-detail {
    scroll-margin-top: 50px;
  }

  .article-tab-content .article-contact-list .person-detail p {
    max-width: 100%;
  }
}

@media screen and (width <= 500px) {
  .article-tab-content .article-contact-list li {
    width: 50%;
  }
}

@media screen and (width <= 350px) {
  .article-tab-content .article-contact-list li {
    width: 100%;
  }
}

.article-edit {
  width: 100%;
}

.article-edit .article-tab-head-act h2 {
  min-width: 200px;
  padding: 10px 0 0;
}

.article-edit .article-tab-head-act h2 input {
  min-width: 200px;
  padding: 4px 8px;
  font-size: 14px;
}

.article-edit .article-tab-head-act h2:after {
  display: none;
}

.article-edit textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 300px;
  min-height: 300px;
}

.article-edit .p-markdowninfo {
  text-align: right;
  padding: 4px 0 0;
  font-size: 11px;
}

.article-edit-btns {
  width: 100%;
  padding: 0;
}

.article-edit-btns a {
  vertical-align: top;
  cursor: pointer;
  color: #000;
  background: none;
  border: 0;
  border-radius: 2px;
  height: 24px;
  margin: 4px 2px 4px 0;
  padding: 0 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

.article-edit-btns a:hover {
  color: #fff;
  background: #000;
}

.fadeout {
  opacity: 0;
  transition: opacity .3s ease-out;
}

.fadeout-visible {
  opacity: 1;
}

.crop-image-container {
  z-index: 1100;
  background: #fff;
  position: absolute !important;
}

.crop-image-container:before {
  content: "";
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.crop-image-container-action {
  text-align: right;
  z-index: 2;
  background: #fff;
  padding: 10px;
  position: relative;
}

.crop-image-container-action button {
  color: #000;
  background: #00eda4;
  border-radius: 4px;
  margin: 0 0 0 10px;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
}

.crop-image-container-action button:hover {
  color: #fff;
  background: #000;
}

.crop-image-container-action button:disabled, .crop-image-container-action button:disabled:hover {
  color: #fff;
  cursor: default;
  background: #bbb;
}

.crop-image-container-action button.btn-cancel {
  background: none;
}

.crop-image-container-action button.btn-cancel:hover {
  color: #00eda4;
  background: none;
}

.ReactCrop {
  vertical-align: top;
  width: 100%;
  height: 100%;
  cursor: default !important;
}

.files-editor-video-file:after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}

.activity-overlay {
  opacity: .8;
  z-index: 1200;
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.activity-overlay p {
  color: #fff;
  text-align: center;
  width: 100%;
  height: 30px;
  margin-top: -15px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  position: fixed;
  top: 50%;
  left: 0;
}

.internal-person h3, .internal-person span {
  opacity: .4;
}

.sketch-picker label {
  min-width: auto;
}

.item-related-cols {
  columns: 2;
}
/*# sourceMappingURL=index.5556987d.css.map */
